<template>
  <div class="list-a" v-loading="loading">
    <div v-if="noData" class="no-data">
      <img :src="img" alt="">
    </div>
    <ul>
      <li v-for="(item, index) of list" :key="index"  class="news-li-s news-li-border">
        <div class="news-left" style="width: 650px;" @click="itemClick(item.id)">{{item.title}}</div>
        <!-- <div class="news-time">{{item.publishTime}}</div> -->
      </li>
    </ul>
    <Pagination :total="total" :size="size" :page="current" @PaginationFun="PaginationFun"></Pagination>
  </div>
</template>

<script>
import { companyeList } from '@/api/web'
import Pagination from '@/components/pagination/Pagination.vue'
export default {
  components: {
    Pagination
  },
  name: 'ListUnit',
  data () {
    return {
      img: require('../../../assets/images/nodata.png'),
      noData: false,
      loading: false,
      list: [],
      total: 0,
      current: 1,
      size: 10
    }
  },
  watch: {
    '$route.fullPath': function (n, o) {
      this.current = 1
      this.initialData()
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    itemClick (id) {
      const { href } = this.$router.resolve({
        path: '/unitdetail',
        query: { id: this.$route.query.id, articleId: id }
      })
      window.open(href, '_blank')
    },
    initialData () {
      this.loading = true
      const data = {
        pageSize: this.size,
        pageNo: this.current
      }
      companyeList(data).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.list = res.result.companyPage.records
          this.total = res.result.companyPage.total
          this.current = res.result.companyPage.current
          if (res.result.total === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
        }
      })
    },
    PaginationFun (e) {
      this.current = e
      this.initialData()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../assets/style/list.css";
.list-a{
  padding: 20px 25px 80px 25px;
  min-height: 520px;
  position: relative;
}
</style>
